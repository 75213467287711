import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Lo que dicen de nosotros',
    paragraph: ''
  };

  const sectionHeader2 = {
    title: 'Trabajamos en conjunto',
    paragraph: ' '
  };

  const sectionHeader3 = {
    title: 'Presencia',
    paragraph: ' '
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">

      <div className="center-content">
          <SectionHeader data={sectionHeader2} className="center-content" />
          <div >
            <p>
            <a href="https://siavial.com" target="_blank"><span className=" text-color-primary" > <b>SIAVIAL</b></span></a> es una plataforma digital que ofrece 
            <span className=" text-color-primary"> <b>Servicios de Asistencia Vial</b></span> dirigida a todos los 
            conductores de un vehículo automor; especializada en flotillas, arrendadoras de autos; ideal para vehículos 
            de circulación sin póliza de seguro que los respalde. 
            </p>
            <div >
              <a href="https://siavial.com" target="_blank">
            <Image
                  src={require('./../../assets/images/logo-siavial.png')}
                  alt="Features split 01"
                  className="imgSiavial"
                  width={132}
                  height={99} />
                  </a>
              </div>

          </div>
      </div>

      <div className="center-content">
          <SectionHeader data={sectionHeader3} className="center-content " />
          <div style={{marginTop: "-60px"}}>
            <p>
            Guadalajara - CDMX - EDOMEX - Pachuca - Hermosillo - Mérida - Mazatlán - Nayarit - Manzanillo - Puebla - Quintana Roo - Monterrey
            </p>
            <div >
              <a href="https://siavial.com" target="_blank">
            <Image
                  src={require('./../../assets/images/mapa-presencia.png')}
                  alt="Features split 01"
                  className="imgSiavial"
                  width={396}
                  height={300} />
                  </a>
              </div>

          </div>
      </div>


      <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content " />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — La indemnización fue muy apegada a los daños del accidente que tuve , y el perito tuvo un trato y proceso amable conmigo.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Gustavo Salazar</span>
                 {/*  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a> 
                  </span> */}
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — El análisis de los daños fueron muy detallados y justificados de muy buena forma. El perito llego muy rápido al lugar del accidente.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Alan Castellano</span>
                  {/* <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                   <a href="#0">AppName</a> 
                  </span> */}
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — El perito realizo un muy buen trabajo y se llego a acuerdos de manera muy rápida para cubrir los daños de mi accidente.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Rogelio Villanueva</span>
                  {/* <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a> 
                  </span> */}
                </div>
              </div>
            </div>

          </div>
        </div>

      

      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;