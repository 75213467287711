import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { useHistory } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const sectionHeader = {
  title: 'Fundador ',
  paragraph: ''
};



const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  let history = useHistory();

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  const mostrarPerfil = ()=> {
    history.push("/perfil")
  }


  return (
    <section
      {...props}
      className={outerClasses}
    >
    
      <div className="container" id="scroller">
        
        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content" />
          <div className="header-nav-inner">
          <Image
            src={require('./../../assets/images/enrique.jpeg')}
            alt=""
            className="imgRedonda"
            onClick={mostrarPerfil}
          />
              <h4 className="header-nav-inner" style={{color:"black"}}>
                Enrique Rodríguez
              </h4>
              <h6 className="header-nav-inner" style={{color:"black"}}>
                15 años de experiencia como ajustador de autos en el sector asegurador.
              </h6>
              <h6 className="header-nav-inner" style={{color:"black"}}>
                Perito de hechos de tránsito terrestre y en identificación vehícular.
              </h6>
              <h6 className="header-nav-inner" style={{color:"black"}}>
                Criminalista de campo.
              </h6>
              <h6 className="header-nav-inner" style={{color:"black"}}>
                Coach y consultor en seguridad vial.
              </h6>
              <h6 className="header-nav-inner" style={{color:"black"}}>
                Abogado y Sub Oficial PFP.
              </h6>
              <h5 className="header-nav-inner" style={{color:"black"}}>
                Tel: (331)-349-5679
              </h5>
              <h5 className="header-nav-inner" style={{color:"black"}}>
                Email: enrique_pfp@hotmail.com
              </h5>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;