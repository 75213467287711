import React from 'react';
// import sections

import Curiculum from '../components/sections/Curiculum';


const Perfil = () => {

  return (
    <>
      <Curiculum />
    </>
  );
}

export default Perfil;