import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import { useHistory } from 'react-router-dom';
import {  Grid ,Icon, IconButton, Typography } from '@material-ui/core';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Curiculum = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  useEffect(() => {
    window.scrollTo(0, 0);
   
}, [])

  
  
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
      <Grid container>

        <Grid item sm={12} xs={12} className="flex-container">
          <Image
              src={require('./../../assets/images/enrique.jpeg')}
              alt=""
              className="imgRedonda"
            />
        </Grid>

          <Grid container className="mt-2">
          <Grid item md={2}></Grid>
            <Grid item md={8} xs={12}>

              <Typography style={{textAlign: "center" , color:"#e6c300"}}>
                 <b>Lic. Enrique Rodríguez Acéves</b>
                </Typography> 

                <Typography style={{textAlign: "center" , color:"#e6c300"}}>
                 <b>Ajustador de HDI Seguros México desde el 2012 a la actualidad</b>
                </Typography> 

                <Typography className="mt-2">
                Más de 10 años de experiencia en el ramo de deslinde de responsabilidades
                de hechos de tránsito terrestre,resolución de conflictos , toma de desiciones, 
                trabajo bajo presión y con Objetivos. 
                </Typography> 

                <Typography className="mt-2" >
                Cédula de Ajustador vigente de la Comisión Nacional de Seguros y Fianzas (CNSF).
                </Typography>
                
                <Typography className="mt-2" >
                Curso de conducción OFF ROAD BMW de motociclistas. 
              
                
              </Typography>

              <Typography className="mt-2" >
                Curso de primeros auxilios Innova Securite (Servicios de Capacitación).
              </Typography>

              <Typography className="mt-2" >
                Certificado como perito en Hechos de Tránsito Terrestre EC0765 del Consejo Nacional de Normalización y Certificación de Competencias Laborales (Conocer).
              </Typography>

             

              <Typography className="mt-2" >
               Certificado de Seminario Nacional de Derecho Penal y Criminología.
              </Typography>
            </Grid>
            <Grid item md={2}></Grid>
        </Grid>

          

      </Grid>



      </div>
    </section>
  );
}

Curiculum.propTypes = propTypes;
Curiculum.defaultProps = defaultProps;

export default Curiculum;