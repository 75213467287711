import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );


  const sectionHeader2 = {
    title: '¿Quiénes somos?',
    paragraph: 'Somos la integración de profesionales expertos en cada tipo de área en específico para dar solución de una manera eficiente, profesional y ágil a un problema relacionado o derivado de un hecho de trásito terrestre.'
  };

  const sectionHeader3 = {
    title: '¿Qué es un perito?',
    paragraph: ' Un perito o experto, es una persona reconocida como una fuente confliable en un determinado tema, técnica o habilidad, cuya capacidad para juzgar o decidir en forma correcta, equilibrada, e inteligente, le confiere autoridad y estatus por sus pares o por el público en materia específica.'
  };

  const sectionHeader4 = {
    title: '¿Qué es un perito especialista en siniestros?',
    paragraph: 'Es el experto en la materia de hechos de tránsito, identificación vehicular, criminalista, valuación, agente de seguros o cualquier otra área relacionada con el tema de autos, siniestros o seguros; donde se tiene la capacidad de asesorar o resolver una cuestionante de manera técnica y científica, realizando análisis en profundidad de los hechos o necesidades de nuestros clientes identificando las circunstancias que lo rodean. '
  };

  const sectionHeader = {
    title: 'A qué nos dedicamos ...',
    paragraph: 'Auxiliar en el trabajo de los jueces y abogados para resolver los casos de colisión o choque mediante la elaboración de dictámenes periciales qué sirven para determinar quién o quienes son responsables de un accidente de tránsito.'
  };

  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">

        <div className={innerClasses}>
          <SectionHeader data={sectionHeader2} className="center-content" />
        <div className="center-content text-color-primary" style={{marginTop: "-40px"}}>
          <ul>Peritos</ul>
          <ul>Criminalistas</ul>
          <ul>Abogados</ul>
          <ul>Ajustadores</ul>
          <ul>Asesores y analistas en seguros</ul>
          <ul>Valuadores</ul>
        </div>

      
         
          <SectionHeader data={sectionHeader3} className="center-content" />
          <SectionHeader data={sectionHeader4} className="center-content" />

          <div className="center-content">
            <h1 className=" text-color-primary">El problema</h1>
            <ul>Tiempo invertido en resolver temas que no son redituables para nuestros clientes.</ul>
            <ul>Tomar decisiones equivocadas.</ul>
            <ul>No contar con el personal suficiente a nivel nacional.</ul>
            <ul>Gastos de viáticos.</ul>
            <ul>Siniestralidad 24/7/365.</ul>
            <ul>Falta de capacitación constante al personal.</ul>
          </div>

          <div className="center-content">
            <h1 className=" text-color-primary">La solución</h1>
            <ul>Delegar toda situación ajena a su especialidad.</ul>
            <ul>Contamos con la experiencia necesaria para solucionar cualquier tipo de problemare relacionados a hechos de tránsito.</ul>
            <ul>Contamos con el personal capacitado.</ul>
            <ul>Enfocamos nuestra atención al 100% a nuestros clientes.</ul>
            <ul>Atención 24/7/365.</ul>
            <ul>Capacitación en segurirad vial.</ul>
          </div>


         {/*  <SectionHeader data={sectionHeader} className="center-content" /> */}

         
          
        {/*   < div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Causa del siniestro
                    </h4>
                  <p className="m-0 text-sm">
                    Tenemos una visión técnica y gran experiencia para detectar la causa que ha producido el daño asegurado.
                    </p>
                </div>
              </div>
            </div>


            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Propuesta de indemnización
                    </h4>
                  <p className="m-0 text-sm">
                    Es nuestra responsabiliadad realizar la propuesta indemnizatoria con un objetivo profesional y técnico por encima de cualquier otra circunstancia.
                    </p>
                </div>
              </div>
            </div>


          </div> */}
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;